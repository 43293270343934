import gql from "graphql-tag";

import todoItemFields from "./todoItemFields";

export default gql`
  fragment onboardingTodo on OnboardingTodo {
    id
    emailConfirmed {
      ...todoItemFields
    }
    documentsSent {
      ...todoItemFields
    }
    profileCompleted {
      ...todoItemFields
    }
    membershipAgreementSent {
      ...todoItemFields
    }
    membershipAgreementSigned {
      ...todoItemFields
    }
    serviceContractSent {
      ...todoItemFields
    }
    serviceContractSigned {
      ...todoItemFields
    }
    workContractEmail {
      ...todoItemFields
    }
    workContractCall {
      ...todoItemFields
    }
    workContractSent {
      ...todoItemFields
    }
    workContractSigned {
      ...todoItemFields
    }
    healthInsurance {
      ...todoItemFields
    }
    silae {
      ...todoItemFields
    }
    silaeIdFilled {
      ...todoItemFields
    }
  }
  ${todoItemFields}
`;
